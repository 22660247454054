<template>
    <div v-if="materials" class="lessonMaterialsLayout">
        <p v-if="title" class="h4 colorGray lessonMaterialsTitle">{{ title }}</p>

        <div class="lessonMaterials">
            <LessonMaterial
                v-for="item in materials"
                :key="item.title"
                :material="item"
            />
        </div>
    </div>
</template>

<script>
import LessonMaterial from "@/views/components/LessonMaterial/LessonMaterial";

export default {
    name: "LessonMaterials",

    components: {
        LessonMaterial,
    },

    props: {
        title: String,
        materials: Array,
    },
};
</script>

<style lang="scss" scoped>
@import "LessonMaterials.scss";
</style>
