<template>
    <div class="lessonMaterial">
        <div class="lessonMaterial__card">
            <p class="colorFiord lessonMaterial__text">
                <span
                    :class="
                        `title1 colorWhite lessonMaterial__text_icon ${
                            material.type === 'video' ? 'icon--iconPurple' : ''
                        }`
                    "
                >
                    <span v-if="material.type === 'url'">{{ material.type }}</span>
                    <span v-if="material.type === 'pdf'">{{ material.type }}</span>
                    <img
                        v-if="material.type === 'video'"
                        alt="video-solid"
                        src="@/assets/img/pages/study/video-solid.svg"
                    />
                </span>
                {{ material.title }}
            </p>
            <p class="lessonMaterial__buttonBox">
                <a
                    v-if="material.url || material.file_url"
                    class="btn-text"
                    target="_blank"
                    :href="material.url || material.file_url"
                    download
                >
                    <button class="btn-text" v-if="1" @click="viewMaterial()">
                        {{ material.url ? "Смотреть" : material.file_url && "Скачать" }}
                    </button>
                    <button class="btn-text" v-else>
                        {{ material.url ? "Смотреть" : material.file_url && "Скачать" }}
                    </button>
                </a>
                <img
                    v-if="material.insideItemTitle"
                    class="lessonMaterial__buttonBox_icon"
                    v-bind:src="isCardContent ? arrowUp : arrowDown"
                    @click="isCardContent = !isCardContent"
                />
            </p>
        </div>

        <div v-if="isCardContent" class="lessonMaterial__content colorGullGray">
            {{ material.insideItemTitle }}
        </div>
    </div>
</template>

<script>
import Button from "@/views/components/Button/Button";

import ArrowDown from "../../../assets/img/pages/study/arrowDown.svg";
import ArrowUp from "../../../assets/img/pages/study/arrowUp.svg";

export default {
    name: "LessonMaterial",

    components: {
        Button,
    },

    props: {
        material: Object,
    },

    data() {
        return {
            isCardContent: false,
            arrowDown: ArrowDown,
            arrowUp: ArrowUp,
        };
    },

    methods: {
        viewMaterial: async function() {
            const lessonId = this.$store.getters.getLesson.lesson.lesson.lesson._id;
            const blockId = this.$store.getters.getUserProgress.progress.data[0].blocks[
                Number(this.$route.params.block_priority) - 1
            ].blockId;

            const additionalMaterialBody = {
                blockId: `${blockId}`,
                lessonId: `${lessonId}`,
                materialId: this.material._id,
            };
            const response = await this.axios.post(
                `/programs/${this.$route.params.slug}/study/viewed/material`,
                additionalMaterialBody
            );
        },
    },
};
</script>

<style lang="scss" scoped>
@import "LessonMaterial.scss";
</style>
