<template>
    <Layout>
        <PageHeader
            :items="items"
            :title="title"
            :link="`/${this.$route.params.slug}/bonuses`"
        />

        <div
            v-for="material in materials"
            class="pageContent backgroundZircon lectureNotesContentWrapper"
        >
            <LessonMaterials :title=material.title :materials="material.files" />
        </div>
    </Layout>
</template>

<script>
import Layout from "@/views/layouts/Layout";
import PageHeader from "@/views/partials/pageHeader/pageHeader";
import Button from "@/views/components/Button/Button";
import LessonMaterials from "@/views/components/LessonMaterials/LessonMaterials";
import axios from "axios";

export default {
    name: "dashboardSurvey",

    components: {
        Layout,
        PageHeader,
        Button,
        LessonMaterials,
    },

    props: {},

    watch: {
        "$store.getters.getBonus.category": function () {
            this.title = this.$store.getters.getBonus.category.title;
            this.items[1].text = this.$store.getters.getBonus.category.title;
            this.bonus = this.$store.getters.getBonus.category;
            this.renderPageData();
        }
    },

    async beforeMount() {
        await this.updateBonusData();
    },

    async mounted() {
        this.renderPageData();
    },

    data() {
        return {
            title: "",

            items: [
                {
                    text: "Бонусы",
                    to: `/${this.$route.params.slug}/bonuses`,
                },
                {
                    text: "",
                    active: true,
                },
            ],

            bonus: {},
            materials: []
        };
    },

    methods: {
        anonymousClick() {},

        renderPageData: function () {
            if (this.bonus.materials && this.bonus.materials.length > 0) {
                for (const material of this.bonus.materials) {
                    const itemToPush = {
                        title: material.name,
                        files: [{
                            title: material.name
                        }]
                    }

                    switch (material.type) {
                        case "pdf":
                            itemToPush.files[0].type = material.type;
                            itemToPush.files[0].file_url = process.env.VUE_APP_OLD_CLIENT_TEST_URL.substring(0, process.env.VUE_APP_OLD_CLIENT_TEST_URL.length - 1) + material.url;
                            break;
                        case "video":
                            itemToPush.files[0].type = material.type;
                            itemToPush.files[0].url = material.url;
                            break;
                        case "link to article":
                            itemToPush.files[0].type = "url";
                            itemToPush.files[0].url = material.url;
                            break;
                        case "link to partner":
                            itemToPush.files[0].type = "url";
                            itemToPush.files[0].url = material.url;
                            break;
                    }

                    this.materials.push(itemToPush);
                }
            }
        },

        updateBonusData: async function () {
            const getBonusDataResult = await axios.get(
                "/programs/" + this.$route.params.slug + "/bonuses/" + this.$route.params.id
            );
            if (
                getBonusDataResult &&
                getBonusDataResult.data &&
                getBonusDataResult.data.result
            ) {
                await this.$store.dispatch("setBonus", getBonusDataResult.data);
            } else {
                await this.$store.dispatch("clearBonus");
                await this.$store.dispatch("setAlert", {
                    alert: {
                        isActive: true,
                        type: "error",
                        text: "Ошибка при загрузке бонуса",
                    },
                });
            }
        },
    },
};
</script>

<style lang="scss">
@import "./lectureNotes.scss";
</style>
